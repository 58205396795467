var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body px-1 px-sm-5 pt-0"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-1 mb-5"},[_c('p',{staticClass:"text-h5 ma-0 pt-4 pl-4 font-weight-medium"},[_vm._v(" Parámetros ")]),_c('v-divider'),_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_c('strong',[_vm._v(" Debes seleccionar el docente ")])])])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":!_vm.filteredTeachers.length > 0
                          ? 'Cargando docentes'
                          : 'Docentes',"loading":!_vm.filteredTeachers.length > 0,"filled":"","rounded":"","dense":"","clearable":"","items":_vm.filteredTeachers,"item-text":"first_name","item-value":"id_user"},on:{"change":function($event){return _vm.get(_vm.report.teacherId)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]):_vm._e()]}},{key:"item",fn:function({ item }){return [_c('v-list-item-content',{staticClass:"mx-n4 pl-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","lg":"11"}},[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.first_name)+" ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.last_name)+" ")])])],1)],1)],1)]}}]),model:{value:(_vm.report.teacherId),callback:function ($$v) {_vm.$set(_vm.report, "teacherId", $$v)},expression:"report.teacherId"}})],1)],1)],1)],1)],1)],1),(_vm.teacherSchedule.isLoadingTeacherSchedule)?_c('div',[_c('v-sheet',{staticClass:"mx-auto mt-5 py-10 px-4",attrs:{"elevation":"0"}},[_c('p',{staticClass:"text-center font-weight-bold text-h6"},[_vm._v(" Cargando horarios del docente ")]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue darken-2"}})],1)],1)],1)],1):_vm._e(),(!!_vm.teacherSchedule.data.length > 0)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-tabs',[_vm._l((_vm.days.data),function(day){return _c('v-tab',{key:day.id},[_vm._v(" "+_vm._s(day.name)+" ")])}),_vm._l((_vm.days.data),function(day){return _c('v-tab-item',{key:day.id},[_c('v-card',[_c('v-card-text',[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"two-line":""}},[_c('v-list-item-group',[(_vm.getScheduleByDay(day.id).length > 0)?_vm._l((_vm.getScheduleByDay(
                                day.id
                              )),function(schedule,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{key:schedule.idschedule},[_c('v-list-item-title',[_c('b',[_vm._v(" "+_vm._s(schedule.subject)+" ")]),_c('v-chip',{staticClass:"white--text mx-3",attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(schedule.grade)+" - "+_vm._s(!!schedule.speciality ? schedule.speciality : schedule?.section)+" "+_vm._s(schedule.group)+" "),(
                                          schedule.teacher_group
                                            .subject_type == 'Modular'
                                        )?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(schedule?.technicalSection?.section))])])],1),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(schedule.day.name)+" "+_vm._s(schedule.block))]),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2 font-weight-bold"},[_vm._v(_vm._s(schedule.local.name))]),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2"},[_vm._v(" Desde - Hasta: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(schedule.start_date)+" - "+_vm._s(schedule.end_date))])])],1)],1)}):[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("No tiene horarios este día")])],1)],1)]],2)],1)],1)],1)],1)})],2)],1)],1)],1):_vm._e()],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('h3',[_vm._v("Horarios de docentes")]),_c('div',{staticClass:"text-muted text-body-1"},[_vm._v(" Visualización de horarios de docentes. ")])])])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>Horarios de docentes</h3>
            <div class="text-muted text-body-1">
              Visualización de horarios de docentes.
            </div>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 pt-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-1 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debes seleccionar el docente
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        :label="
                          !filteredTeachers.length > 0
                            ? 'Cargando docentes'
                            : 'Docentes'
                        "
                        :loading="!filteredTeachers.length > 0"
                        filled
                        rounded
                        dense
                        clearable
                        :items="filteredTeachers"
                        item-text="first_name"
                        item-value="id_user"
                        v-model="report.teacherId"
                        @change="get(report.teacherId)"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span
                              >{{ item.first_name }} {{ item.last_name }}</span
                            >
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content class="mx-n4 pl-3">
                            <v-row>
                              <v-col cols="10" lg="11">
                                <v-list-item-title>
                                  <span class="font-weight-medium">
                                    {{ item.first_name }}
                                  </span>

                                  <span class="font-weight-medium">
                                    {{ item.last_name }}
                                  </span>
                                </v-list-item-title></v-col
                              >
                            </v-row>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->
            </v-col>
          </v-row>
          <div v-if="teacherSchedule.isLoadingTeacherSchedule">
            <v-sheet class="mx-auto mt-5 py-10 px-4" elevation="0">
              <p class="text-center font-weight-bold text-h6">
                Cargando horarios del docente
              </p>
              <v-row class="mt-5">
                <v-col class="mx-auto" cols="12" md="6" lg="4" xl="3">
                  <v-progress-linear
                    indeterminate
                    color="blue darken-2"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <v-row v-if="!!teacherSchedule.data.length > 0">
            <v-col>
              <v-card elevation="0" outlined>
                <v-tabs>
                  <v-tab v-for="day in days.data" :key="day.id">
                    {{ day.name }}
                  </v-tab>
                  <v-tab-item v-for="day in days.data" :key="day.id">
                    <v-card>
                      <v-card-text>
                        <v-list two-line class="pa-0 ma-0">
                          <v-list-item-group>
                            <template
                              v-if="getScheduleByDay(day.id).length > 0"
                            >
                              <v-list-item
                                v-for="(schedule, index) in getScheduleByDay(
                                  day.id
                                )"
                                :key="index"
                              >
                                <v-list-item-content :key="schedule.idschedule">
                                  <v-list-item-title
                                    ><b> {{ schedule.subject }} </b>
                                    <v-chip
                                      color="primary"
                                      class="white--text mx-3"
                                      small
                                    >
                                      <span
                                        >{{ schedule.grade }} -
                                        {{
                                          !!schedule.speciality
                                            ? schedule.speciality
                                            : schedule?.section
                                        }}
                                        {{ schedule.group }}
                                        <span
                                          v-if="
                                            schedule.teacher_group
                                              .subject_type == 'Modular'
                                          "
                                          >-</span
                                        >
                                        {{
                                          schedule?.technicalSection?.section
                                        }}</span
                                      >
                                    </v-chip>
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    class="text-subtitle-2"
                                    >{{ schedule.day.name }}
                                    {{ schedule.block }}</v-list-item-subtitle
                                  >
                                  <v-list-item-subtitle
                                    class="text-subtitle-2 font-weight-bold"
                                    >{{
                                      schedule.local.name
                                    }}</v-list-item-subtitle
                                  >
                                  <v-list-item-subtitle class="text-subtitle-2">
                                    Desde - Hasta:
                                    <span class="font-weight-bold">
                                      {{ schedule.start_date }} -
                                      {{ schedule.end_date }}</span
                                    >
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <template v-else>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >No tiene horarios este
                                    día</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PURGE_CURRENT_PAGE_ACTIONS,
  FETCH_USERS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import schedulesRepository from "../../../repositories/schedulesRepository";
import daysRepository from "../../../repositories/daysRepository";
import { mapGetters } from "vuex";

export default {
  name: "TeacherSchedule",
  title: "Horarios | GE ITR",
  components: {},
  data() {
    return {
      report: {
        teacherId: "",
        isLoading: false,
      },
      teacherSchedule: {
        data: [],
        isLoadingTeacherSchedule: false,
      },
      days: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Horarios de docentes", route: "teachers-schedules" },
    ]);
    this.$store.dispatch(FETCH_USERS);
    this.getDays();
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getDays() {
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al obtener los datos desde el servidor",
          });
        });
    },
    get(teacher) {
      if (teacher != null) {
        this.getScheduleTeacher();
        return;
      }
      this.teacherSchedule.data = [];
      this.teacherSchedule.isLoadingTeacherSchedule = false;
    },
    getScheduleTeacher() {
      this.teacherSchedule.isLoadingTeacherSchedule = true;
      schedulesRepository
        .getTeacherSchedule(this.report.teacherId)
        .then(({ data }) => {
          this.teacherSchedule.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.teacherSchedule.isLoadingTeacherSchedule = false;
        });
    },
    teacherData(id) {
      return this.teachers.find((item) => item.id_user == id);
    },
    getScheduleByDay(dayId) {
      return this.teacherSchedule.data.filter(
        (schedule) => schedule.day.id === dayId
      );
    },
  },
  computed: {
    teachers() {
      let userTypes = [];
      // if (process.env.APP_ENV === "local") {
      //   userTypes.push("Superadministrador");
      //   userTypes.push("Superadmin");
      // } else {
      //   userTypes.push("Docente académico");
      //   userTypes.push("Docente técnico");
      // }
      userTypes.push("Docente académico");
      userTypes.push("Docente técnico");
      return this.$store.getters.getUsersByUserType(userTypes);
    },

    filteredTeachers() {
      return this.teachers.filter(
        (item) => item.first_name.toLowerCase() || item.last_name.toLowerCase()
      );
    },
    formattedEvents() {
      // Iterar sobre la respuesta de la API y darle la estructura deseada
      return this.teacherSchedule.data.map((schedule) => ({
        name: schedule.subject,
        details: schedule.teacher_group.grade_information,
        start: `${schedule.start_date}T${schedule.class_time.start_time}`,
        end: `${schedule.end_date}T${schedule.class_time.end_time}`,
      }));
    },
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "schedules";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSchedules = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSchedule = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getScheduleByTime = (userId, dayId, time) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/user/${userId}/day/${dayId}/time/${time}`
  );
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getTeacherSchedule = (userId) => {
  return PedagogicalDiaryService.get(`${RESOURCE}/group/user/${userId}`);
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const createSchedule = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const getStudentActiveSchedule = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}/student-current-schedule`, payload);
};

/**
 * PUT request to update a specific duration type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSchedule = (scheduleId, teacherGroupDateId, payload) => {
  return PedagogicalDiaryService.put(
    `${RESOURCE}/${scheduleId}/teacherGroupDate`,
    teacherGroupDateId,
    payload
  );
};

/**
 * DELETE request to delete the specified duration type
 * @param id
 * @returns {*}
 */
export const deleteSchedule = (scheduleId, teacherGroupDateId) => {
  return PedagogicalDiaryService.delete(
    `${RESOURCE}/${scheduleId}/teacherGroupDate/${teacherGroupDateId}`
  );
};

export default {
  getAllSchedules,
  getSchedule,
  createSchedule,
  getStudentActiveSchedule,
  updateSchedule,
  deleteSchedule,
  getScheduleByTime,
  getTeacherSchedule,
};
